import React from "react"
import logo from '../images/logo.svg';
import logoIcon from '../images/logo_icon.svg';
import logoInnerIcon from '../images/inner_logo.svg';
import { Row, Col, Container, ListGroup, Button } from "react-bootstrap"
import clsx from 'clsx';
import { FaCodepen } from 'react-icons/fa';
import { GoGlobe } from 'react-icons/go';
import { GiStack, GiCoffeeBeans } from 'react-icons/gi';

import { MdEmail } from 'react-icons/md';
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby";

import me from '../images/me.jpg';

import Img from 'gatsby-image';

// TODO Add a banner about blog articles, open source and products once I have something to show
// TODO Add a section for products and blogs as well (navmenu)

const IndexPage = ({data}) => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Marcelo Serpa, Full-Stack Sofware Engineer and Creator" 
         keywords={
           [
             'Marcelo de Moraes Serpa', 'programmer', 'software engineer', 'senior', 'fullstack', 'ruby', 'rails', 'rubyonrails', 'javascript', 'typescript', 'products', 'consulting', 'software', 'web development', 'mobile', 'remote',
             'react', 'rest', 'graphql', 'products', 'product development', 'hire', 'api', 'blog', 'creator', 'engineer', 'problem solver', 'solve', 'enterprise', 'startup'
           ]} 
     />
    <div className='avatar-sm-wrapper d-flex d-lg-none'>
      <Img fixed={data.file.childImageSharp.fixed} />
    </div>
    <Container>
      <Row noGutters>
        <Col xs={12} md={12} lg={5} xl={4} className='d-none d-lg-block'>
          <div className='avatar-wrapper'>
            <Img fixed={data.file.childImageSharp.fixed} />
          </div>
        </Col>
        <Col className='bio-text' xs={12} md={12} lg={6} xl={8}>
          <div>
            <h1 className='main-title'>Marcelo Serpa</h1>
            <p>
              <strong>Full-Stack Software Engineer</strong> passionate about <strong>building great products.</strong> 
            </p>
            <p>
              Over 13 years of <a href="https://www.linkedin.com/in/fullofcaffeine">experience</a> in the software industry. Adept at remote work. Passionate creator.
            </p>
            <p>
              Interested in <strong>hiring me</strong>? You may check my <a href='https://linkedin.com/in/fullofcaffeine'>LinkedIn</a> profile or <a href="mailto:hello@fullofcaffeine.co?subject=I'm interested in hiring you, please send me your resume">ask me for my resume.</a> 
            </p>
            <p>
             Stay tuned for upcoming news about other <a href='https://fullofcaffeine.co'>projects of mine</a> ■
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)


export const query = graphql`
  query {
    file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IndexPage
